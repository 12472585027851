@font-face {
  font-family: "Helvetica Neue";
  src: url("../public/webfonts/HelveticaNeue.woff") format("woff"),
    /* Modern Browsers */ url("../public/webfonts/HelveticaNeue.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "HelveticaNeueLTStd-Bd";
  src: url("../public/webfonts/HelveticaNeueLTStd-Bd.woff") format("woff"),
    /* Modern Browsers */ url("../public/webfonts/HelveticaNeueLTStd-Bd.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: bold;
  font-weight: 800;
}

@font-face {
  font-family: "Helvetica Neue Light";
  src: url("../public/webfonts/helveticaneuelight.woff") format("woff"),
    /* Modern Browsers */ url("../public/webfonts/helveticaneuelight.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 400;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  font-family: "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #383838;
  background: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  background: #223445;
  width: 100%;
  height: 40px;
  padding: 5px 0px;
}

#firebaseui_container {
  margin-top: 250%;
  background: #fafafa;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-family: "Helvetica Neue";
  width: 300px;
}
#firebaseui_container .firebaseui-container {
  font-family: "Helvetica Neue";
}

main {
  position: relative;
  margin-top: -50px;
  padding: 10px;
}

.btn-back {
  margin-top: -11px;
  text-align: left;
  margin-left: 0px;
  float: left;
}

.signOut {
  float: right;
  margin-top: -11px;
}

h1 {
  font-family: "Helvetica Neue";
  font-size: min(6vw - 4px, 30px);
  color: #383838;
  letter-spacing: 0;
  margin: 0.5em 0px;
}

h2 {
  font-family: "Helvetica Neue";
  font-size: 20px;
  color: #383838;
  letter-spacing: 0;
  margin: 0px 0px 8px 0px;
  text-align: left;
}

.card {
  background: #fafafa;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 10px 20px;
  margin-bottom: 15px;
  /* max-height: 30vh; */
}

.card-2 {
  background: #fafafa;
  border-radius: 4px;
  border: 2px solid rgb(209, 209, 209);
}

.card-2:hover {
  background: rgb(209, 209, 209);
}

.date {
  font-family: "Helvetica Neue";
  font-size: 13px;
  color: #383838;
  letter-spacing: 0;
  text-align: right;
  float: right;
  margin-top: 8px;
  font-weight: 200;
}

dl {
  margin: 0 0 7px 0;
  width: 100%;
  float: left;
  font-size: 13px;
  font-weight: 200;
}

dt {
  float: left;
  width: 40%;
  text-align: right;
  margin: 0px 1% 0px 0px;
  padding: 0;
}

dd {
  width: 59%;
  float: left;
  margin: 0px;
  padding: 0;
  text-align: left;
}
