.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.center {
  position: absolute;
  left: 50%;
  top: -1100%;
  margin-left: -150px;
}

.mapOuter {
  display: flex;
  justify-content: center;
  margin-right: 5px;
}

/*Viewport width of <404 is when text starts wrapping*/
.mapInner {
  width: 100%;
  height: 100%;
}

.modal::backdrop {
  background: hsl(0 0% 0% / 50%);
}

.modal {
  max-width: 20rem;
  padding: 2rem;
  border-radius: 0.5rem;
  position: fixed;
  box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
  border: 1px solid #ccc;
}

.modal-close-btn {
  font-size: 0.75em;
  position: absolute;
  top: 0.25em;
  right: 0.25em;
}

.button {
  background-color: #3770ef; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

button:disabled,button[disabled]{
  opacity: 0.6;
  cursor: not-allowed;
}

input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.error {
  color: #f44336;
}

.alert {
  position: fixed;
  bottom: 20px;
  width: 100%;
  padding: 20px;
  color: white;
}

.closebtn {
  font-size: 0.75em;
  position: absolute;
  top: 20px;
  right: 100px;
  color: white;
}